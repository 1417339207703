import { ChangeEvent, FocusEvent, FormEvent, useCallback, useState } from 'react'
import { AnySchema, object, ValidationError } from 'yup'
import Lazy from 'yup/lib/Lazy'
import { useRugBuilder } from './use-rug-builder'
import { RugBuilderActionTypes } from '@context/rug-builder'

export type TouchedFields<T> = Partial<Record<keyof T, boolean>>
export type ErrorFields<T> = Partial<Record<keyof T, string>>
export type YupSchemaObject<T> = Record<keyof T, Lazy<AnySchema>>

const serializeYupErrors = <T>(err: ValidationError, touchedFields?: TouchedFields<T>) => {
  return err.inner.reduce((acc: ErrorFields<T>, val: ValidationError) => {
    const fieldName = val.path as keyof T | undefined

    if (touchedFields) {
      if (fieldName && touchedFields[fieldName]) acc[fieldName] = val.message
    } else {
      if (fieldName) acc[fieldName] = val.message
    }

    return acc
  }, {})
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidationError = (err: any): err is ValidationError => {
  return 'inner' in err
}

export const useForm = <Values extends Record<string, unknown>>(
  initialValues: Values,
  submitHandler: (values: Values) => Promise<void>,
  validationSchema?: YupSchemaObject<Values>,
  debouncedHandler?: (name: string, value: string) => void,
) => {
  const { dispatch } = useRugBuilder()
  const [values, setValues] = useState<Values>(initialValues)
  const [errors, setErrors] = useState<ErrorFields<Values>>({})
  const [touched, setTouched] = useState<TouchedFields<Values>>({})
  let debounceTimer: NodeJS.Timeout | null = null

  const validate = useCallback(async () => {
    if (validationSchema) {
      const schema = object(validationSchema)
      await schema.validate(values, { abortEarly: false })
    }
  }, [validationSchema, values])

  const debouncedHandleChange = useCallback(
    (name: string, value: string) => {
      if (debounceTimer) clearTimeout(debounceTimer)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debounceTimer = setTimeout(() => {
        if (typeof debouncedHandler === 'function') debouncedHandler(name, value)
      }, 300)
    },
    [debouncedHandler],
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { currentTarget } = event
      setValues((prevValues) => ({
        ...prevValues,
        [currentTarget.name]: currentTarget.value,
      }))
      dispatch({
        type: RugBuilderActionTypes.HANDLE_DIMENSION_ERROR,
        handleDimensionErrorPayload: false,
      })
      debouncedHandleChange(currentTarget.name, currentTarget.value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedHandleChange],
  )

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const { currentTarget } = event

      if (!touched[currentTarget.name]) {
        setTouched((prevValues) => ({
          ...prevValues,
          [currentTarget.name]: true,
        }))
      }

      const touchedFields = { ...touched, [currentTarget.name]: true }

      validate()
        .then(() => {
          setErrors({})
          dispatch({
            type: RugBuilderActionTypes.HANDLE_DIMENSION_ERROR,
            handleDimensionErrorPayload: false,
          })
        })
        .catch((err: ValidationError) => {
          dispatch({
            type: RugBuilderActionTypes.HANDLE_DIMENSION_ERROR,
            handleDimensionErrorPayload: true,
          })
          const validationErrors = serializeYupErrors<Values>(err, touchedFields)
          setErrors(validationErrors)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [touched, validate],
  )

  const resetValues = useCallback(() => {
    setValues(initialValues)
    setErrors({})
  }, [initialValues])

  const handleSubmit = useCallback(
    async (event?: FormEvent<HTMLFormElement>) => {
      event?.preventDefault()

      try {
        await validate()

        await submitHandler(values)
      } catch (err) {
        const validationErrors = isValidationError(err) ? serializeYupErrors<Values>(err) : {}

        setErrors(validationErrors)

        const touchedFields: TouchedFields<Values> = {}

        Object.keys(initialValues).forEach((item: keyof Values) => {
          touchedFields[item] = true
        })

        setTouched(touchedFields)
      }
    },
    [initialValues, submitHandler, validate, values],
  )

  return {
    values,
    errors,
    resetValues,
    handleChange,
    handleBlur,
    handleSubmit,
  }
}
